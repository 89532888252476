<template>
  <div class="header" :style="backgroudstyle">
    <div class="headerContent">
      <router-link :to="{ name: 'Index' }">
        <div class="leftContent">
          <img
            v-if="activeIndex == 0"
            src="~assets/img/Header/LOGO@2x.png"
            alt=""
          />
          <img
            v-if="activeIndex == 1"
            src="~assets/img/Header/LOGOBlue.png"
            alt=""
          />
          <div class="dtsumName" :style="backgroudstyle">三象工业互联网</div>
        </div></router-link
      >
      <div :class="activeIndex == 0 ? 'RightContent0' : 'RightContent1'">
        <el-menu class="el-menu-demo" mode="horizontal" text-color="#fff">
          <el-menu-item index="6">
            <div @click="toDtsum">低代码开发平台</div></el-menu-item
          >
          <el-submenu index="1">
            <template slot="title" class="title">行业工业互联网平台</template>

            <el-menu-item index="1-1"
              ><a href="https://fzxd.dtsum.com/#/index"
                >纺织行业工业互联网平台</a
              >
            </el-menu-item>

            <el-menu-item index="1-2">
              <a href="http://jgj.dtsum.com/#/">紧固件行业工业互联网平台</a>
            </el-menu-item>
            <el-menu-item index="1-3"
              ><a href=" http://qp.dtsum.com/#/"> 岱山工业互联网平台</a>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">解决方案</template>
            <el-menu-item index="2-1"
              ><router-link :to="{ name: 'SolutionTwo' }"
                >纺织行业数字化工厂解决方案</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-2"
              ><router-link :to="{ name: 'SolutionOne' }"
                >紧固件行业数字化工厂解决方案</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-3"
              ><router-link :to="{ name: 'SolutionThree' }"
                >汽船配行业数字化工厂解决方案</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">开发者平台</template>

            <el-menu-item index="3-1"
              ><router-link :to="{ name: 'LowCodePlatform' }">
                三象低代码平台
              </router-link></el-menu-item
            >

            <el-menu-item index="3-2">
              <router-link :to="{ name: 'IOTPlatform' }"
                >三象IOT平台</router-link
              ></el-menu-item
            >
            <el-menu-item index="3-3">
              <router-link :to="{ name: 'BIPlatform' }">
                三象BI平台</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="4">
            <template slot="title" class="title">案例精选</template>
            <el-menu-item index="4-1"
              ><router-link :to="{ name: 'CustomerAOYA' }"
                >纺织行业案例
              </router-link></el-menu-item
            >
            <el-menu-item index="4-2"
              ><router-link :to="{ name: 'CustomerQljgj' }"
                >紧固件行业案例
              </router-link></el-menu-item
            >
            <el-menu-item index="4-3"
              ><router-link :to="{ name: 'CustomerDZMotor' }"
                >电机行业案例
              </router-link></el-menu-item
            >
            <el-menu-item index="4-4"
              ><router-link :to="{ name: 'CustomerJXSponge' }"
                >汽配行业案例
              </router-link></el-menu-item
            >
          </el-submenu>

          <el-submenu index="5">
            <template slot="title" class="title">产品演示</template>
            <el-menu-item index="5-1">
              <a href="http://jf.dtsum.com">家纺行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-2">
              <a href="http://jgjys.dtsum.com">紧固件行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-3">
              <a href="http://jyx.dtsum.com">金银线行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-4">
              <a href="http://zx.dtsum.com">制线行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-5">
              <a href="http://dj.dtsum.com">电机行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-6">
              <a href="http://qp.dtsum.com">汽配行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-7">
              <a href="http://zs.dtsum.com">注塑行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-8">
              <a href="http://testzt.dtsum.com">线带纺织行业数字化管控系统</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="6" class="AboutUs">
            <router-link :to="{ name: 'AboutUs' }"
              >关于我们</router-link
            ></el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    toDtsum() {
      window.open("http://siku.dtsum.com", "_self");
    }
  },
  computed: {
    backgroudstyle() {
      return this.activeIndex === 1
        ? { background: "white", color: "#1e90ff" }
        : {};
    }
  },
  created() {
    if (this.$route.path === "/Index") {
      this.activeIndex = 0;
    } else {
      this.activeIndex = 1;
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  .headerContent {
    width: 1420px;
    height: 64px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .leftContent {
      width: 315px;
      height: 64px;
      line-height: 64px;
      display: flex;
      justify-content: space-between;
      img {
        width: 114px;
        height: 23px;
        margin-top: 22px;
        vertical-align: text-bottom;
      }
      .dtsumName {
        vertical-align: middle;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 3px;
      }
    }
  }
}
</style>
<style lang="less">
.RightContent0 {
  .el-menu {
    padding: 0;
    background-color: transparent !important;
    border: none !important;
  }
  /* //二次菜单悬浮及背景样式 */
  .el-menu--popup-bottom-start .el-menu-item:hover {
    color: #3d7eff !important;
    background-color: #fff !important;
  }
  .el-menu--popup-bottom-start .el-menu-item {
    background: #fff !important;
    color: #7a8e9d !important;
  }

  /* //鼠标悬浮时，子菜单的样式 应用于只有一级的菜单*/
  .el-menu-item:hover {
    outline: 0 !important;
    color: #fff !important;
    background: none !important;
    border: none !important;
  }
  .el-menu-item {
    border: none !important;
    // padding: 0 !important;
  }
  .AboutUs {
    padding: 0 !important;
  }

  /* //鼠标悬浮时，主菜单的样式 应用于多级菜单的主菜单*/
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0 !important;
    color: #fff !important;
    background: none !important;
    border: none !important;
  }
  .el-submenu__title {
    color: #fff !important;
    border: none !important;
  }
  .el-icon-arrow-down:before {
    color: #fff;
  }
  .router-link-active {
    text-decoration: none;
  }
}
.RightContent1 {
  .el-menu {
    background-color: transparent !important;
    border: none !important;
    color: #1e90ff !important;
  }
  /* //二次菜单悬浮及背景样式 */
  .el-menu--popup-bottom-start .el-menu-item:hover {
    color: #3d7eff !important;
    background-color: #fff !important;
  }
  .el-menu--popup-bottom-start .el-menu-item {
    background: #fff !important;
    color: #7a8e9d !important;
  }

  /* //鼠标悬浮时，子菜单的样式 应用于只有一级的菜单*/
  .el-menu-item:hover {
    outline: 0 !important;
    color: #1e90ff !important;
    border: none !important;
  }
  .el-menu-item {
    border: none !important;
    color: #1e90ff !important;
    // padding: 0 !important;
  }
  .AboutUs {
    padding: 0 !important;
  }
  .el-submenu__title {
    color: #1e90ff !important;
    border: none !important;
  }

  /* //鼠标悬浮时，主菜单的样式 应用于多级菜单的主菜单*/
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0 !important;
    color: #1e90ff !important;
    background: none !important;
    border: none !important;
  }
  .el-icon-arrow-down:before {
    color: #1e90ff;
  }
  .router-link-active {
    text-decoration: none;
  }
}
</style>
